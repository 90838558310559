$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #18191b;
$foreground-color: #1e2022;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #3e83a1;
$theme-color-2: #304d72;
$theme-color-3: #637383;
$theme-color-4: #405264;
$theme-color-5: #426188;
$theme-color-6: #4d5f72;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #509ab9;
$gradient-color-2 : #3a7a96;
$gradient-color-3 : #4386a3;

$lp-bg-color-1 : #00365a;
$lp-bg-color-2 : #184f90;
$lp-bg-color-3 : #8a9fb4;
$lp-bg-color-4 : #2c4d6e;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 2 6 10 14 19;
$shadowOpacitiesTop: 0.1 0.3 0.6 0.7 0.8;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.1 0.3 0.6 0.7 0.8;

$logoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$logoPathMobile: "../../../assets/img/eTrappLogoDesignFINAL.svg";

$lpLogoPath: "../../../assets/img/eTrappLogoDesignFINAL.svg";
$lpLogoPathPinned: "../../../assets/img/eTrappLogoDesignFINAL.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
